import './About.css';
import imgMatheus from '../resources/imgMatheus.jpeg'

function About() {
    return (
        <div className="about-container">
            <div className="about-content">
                <div className="about-header">
                    <div className="about-image">
                        <img src={imgMatheus} alt="Matheus Boa Sorte" />
                    </div>
                    <div className="about-details">
                        <h1 className="about-title">Matheus Boa Sorte</h1>
                        <h2 className="subtitle">Quem sou eu?</h2>
                    </div>
                </div>
                <div className="about-body">
                    <p className="about-text">
                        Sou um apaixonado pelas tradições culturais do Nordeste brasileiro. Pra ser mais específico, do sertão, o qual defendo ser muito mais que um canto de Brasil que chove pouco. Sertão é um estado de espírito, que carrega em si o Sorriso, a Fé, a Persistência, a Coragem e um pouco de malícia.
                    </p>
                    <p className="about-text">
                        Publicitário, cantor, compositor, escritor, educador... E muito mais! Mas, no fundo, sou apenas um <strong>CONTADOR DE HISTÓRIAS</strong>. Em cada palco, programa de TV ou música, busco compartilhar algo novo.
                    </p>
                </div>
                <blockquote className="quote">
                    <span className="quote-left">
                        <i className="fa-solid fa-quote-left"></i>
                    </span>
                    <span className="quote-text">
                        A Vida é um forró e a gente dança conforme a música que toca no Coração!
                    </span>
                    <span className="quote-right">
                        <i className="fa-solid fa-quote-right"></i>
                    </span>
                </blockquote>
            </div>
        </div>
    );
}

export default About;
