import React from 'react'
import Footer from '../Footer';
import VideoSec from '../VideoSec';
import PhotoSec from '../PhotoSec';
import FaqSec from '../FaqSec';
import Imprensa from '../Imprensa';
import Marcas from '../Marcas';
import Nav from '../Nav';
import About from '../About'

function Sobre() {
    return (
        <>
            <Nav></Nav>
            <About></About>
            <VideoSec></VideoSec>
            <PhotoSec></PhotoSec>
            <Marcas></Marcas>
            <FaqSec></FaqSec>
            <Footer></Footer>
        </>
    )
}

export default Sobre