import React, { useState, useEffect } from 'react';
import { getMessages, getPhotos } from './services/apiService';
import './BlogHighlights.css';

function BlogHighlights() {
    const [messages, setMessages] = useState([]);
    const [images, setImages] = useState({});

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            const data = await getMessages();
            const messagesWithDate = data.map((message) => ({
                ...message,
                createdAt: message.createdAt || new Date().toISOString(),
            }));
            setMessages(messagesWithDate.slice(0, 3));
            fetchImagesForMessages(messagesWithDate.slice(0, 3));
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const fetchImagesForMessages = async (messages) => {
        try {
            const allPhotos = await getPhotos();
            const imagesMap = {};

            allPhotos.forEach((photo) => {
                if (photo.news && photo.news.id) {
                    if (!imagesMap[photo.news.id]) {
                        imagesMap[photo.news.id] = [];
                    }
                    imagesMap[photo.news.id].push(`data:image/jpeg;base64,${photo.fileData}`);
                }
            });

            setImages(imagesMap);
        } catch (error) {
            console.error('Failed to fetch images:', error);
        }
    };

    const getPreviewContent = (content) => {
        const plainText = content.replace(/<[^>]*>/g, '');
        return plainText.length > 150 ? plainText.slice(0, 150) + '...' : plainText;
    };

    return (
        <div className="blog-highlights-container">
            <h1 className="blog-highlights-title">Destaques do Blog</h1>
            <div className="blog-highlights-grid">
                {messages.map((message) => (
                    <div key={message.id} className="blog-highlight-card">
                        <div className="blog-highlight-image">
                            {images[message.id] && images[message.id][0] ? (
                                <img
                                    src={images[message.id][0]}
                                    alt={`Highlight image for ${message.title}`}
                                    className="highlight-image"
                                />
                            ) : (
                                <div className="no-image">Carregando imagem...</div>
                            )}
                        </div>
                        <div className="blog-highlight-content">
                            <h2 className="highlight-title">{message.title}</h2>
                            <p className="highlight-excerpt">{getPreviewContent(message.content)}</p>
                            <a href={`/blog/${message.id}`} className="read-more-button">
                                Ver mais...
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BlogHighlights;
