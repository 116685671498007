import React, { useState, useEffect } from 'react';
import '../components/ScrollUp.css';

function ScrollUp() {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button
            onClick={scrollToTop}
            className={`scroll-up ${showButton ? 'show' : 'hide'}`}
        >
            <i className="fas fa-arrow-up"></i>
        </button>
    );
}

export default ScrollUp;