import React from 'react';
import img from '../resources/imgCiclo.png'
import './ArticleHighlights.css';

const articles = [
  {
    id: 1,
    title: "Owner of Website Mentioned in TechCrunch",
    image: img,
    link: "/articles/article-1"
  },
  {
    id: 2,
    title: "Owner's Expertise Highlighted in Forbes",
    image: img,
    link: "/articles/article-2"
  },
  {
    id: 3,
    title: "Exclusive Interview with the Owner on Wired",
    image: img,
    link: "/articles/article-3"
  }
];

const ArticleHighlights = () => {
  return (
    <div className="article-highlights-container">
      <div className="article-grid">
        {articles.map((article) => (
          <div key={article.id} className="article-card">
            <a href={article.link} className="article-card-link">
              <div className="article-card-image">
                <img src={article.image} alt={article.title} />
              </div>
              <div className="article-card-content">
                <h3 className="article-card-title">{article.title}</h3>
                <button className="ver-mais-button">Ver mais...</button>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleHighlights;