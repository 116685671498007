import React, { useState, useEffect } from 'react';
import { getMessages, getPhotos } from './services/apiService';
import './BlogComponent.css';

function BlogComponent() {
    const [messages, setMessages] = useState([]);
    const [images, setImages] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [viewingPost, setViewingPost] = useState(null);

    useEffect(() => {
        fetchMessages();
    }, []);

    useEffect(() => {
        filterMessages();
    }, [searchQuery, messages]);

    const fetchMessages = async () => {
        try {
            const data = await getMessages();
            const messagesWithDate = data.map((message) => ({
                ...message,
                createdAt: message.createdAt || new Date().toISOString(),
            }));
            setMessages(messagesWithDate);
            fetchImagesForMessages(messagesWithDate);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const fetchImagesForMessages = async (messages) => {
        try {
            const allPhotos = await getPhotos();
            const imagesMap = {};

            allPhotos.forEach((photo) => {
                if (photo.news && photo.news.id) {
                    if (!imagesMap[photo.news.id]) {
                        imagesMap[photo.news.id] = [];
                    }
                    imagesMap[photo.news.id].push(`data:image/jpeg;base64,${photo.fileData}`);
                }
            });

            setImages(imagesMap);
        } catch (error) {
            console.error('Failed to fetch images:', error);
        }
    };

    const filterMessages = () => {
        const filtered = messages.filter((message) =>
            message.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredMessages(filtered);
    };

    const handlePostClick = (post) => {
        setViewingPost(post);
    };

    const handleBackToList = () => {
        setViewingPost(null);
    };

    const stripHtmlTags = (html) => {
        return html.replace(/<[^>]*>/g, '');
    };

    const getPreviewContent = (content) => {
        const plainText = stripHtmlTags(content);
        return plainText.length > 150 ? plainText.slice(0, 150) + '...' : plainText;
    };

    const renderHtmlContent = (content) => {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    };

    return (
        <div className="blog-container">
            {!viewingPost ? (
                <>
                    <h1 className="blog-title">Posts mais recentes</h1>

                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Pesquisar por título..."
                            className="search-input"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    <div className="blog-list">
                        {filteredMessages.length > 0 ? (
                            filteredMessages.map((message) => (
                                <div
                                    className="blog-card"
                                    key={message.id}
                                    onClick={() => handlePostClick(message)}
                                >
                                    <div className="blog-card-image">
                                        {images[message.id] && images[message.id][0] ? (
                                            <img
                                                src={images[message.id][0]}
                                                alt={`Post photo for ${message.title}`}
                                            />
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                    <div className="blog-card-content">
                                        <h2 className="blog-card-title">{message.title}</h2>
                                        <p className="blog-card-excerpt">
                                            {getPreviewContent(message.content)}
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="no-results">Nenhum post encontrado...</p>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className="blog-post-detail">
                        <button className="back-to-list-button" onClick={handleBackToList}>Voltar</button>
                        <h1 className="blog-post-title">{viewingPost.title}</h1>
                        <p className="blog-post-date">
                            Matéria postada em: {viewingPost.createdAt
                                ? new Date(viewingPost.createdAt).toLocaleDateString()
                                : 'Data desconhecida'}
                        </p>

                        <div className="blog-post-images">
                            {images[viewingPost.id] && images[viewingPost.id][0] && (
                                <img
                                    src={images[viewingPost.id][0]}
                                    alt={`Expanded image for ${viewingPost.title}`}
                                    className="expanded-image"
                                />
                            )}
                        </div>

                        <div className="blog-post-content">
                            {renderHtmlContent(viewingPost.content)}
                        </div>

                        <div className="blog-post-images">
                            {images[viewingPost.id] && images[viewingPost.id][1] && (
                                <img
                                    src={images[viewingPost.id][1]}
                                    alt={`Second expanded image for ${viewingPost.title}`}
                                    className="expanded-image second-image"
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default BlogComponent;