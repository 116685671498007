import React from 'react'
import './Imprensa.css';

function Imprensa() {
    return (
        <>
            <div className='press-container'>
                <h1 className='press-title'>O QUE DIZ A IMPRENSA</h1>
                <div className="press-title-row">
                    <div className="press-column">
                        <img src={require('../resources/imgBannerCiclo.png')} className='press-photo' alt="Livro 1" />
                    </div>
                    <div className="press-column">
                        <img src={require('../resources/imgLivro.png')} className='press-photo' alt="Livro 2" />
                    </div>
                    <div className="press-column">
                        <img src={require('../resources/imgLivro.png')} className='press-photo' alt="Livro 3" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Imprensa