import React, { useState, useEffect } from 'react';
import { getMessages, saveMessage, updateMessage, deleteMessage, savePhoto, logout, getPhotos, deletePhoto, updatePhoto, saveProductPhoto } from './services/apiService';
import './MessageComponent.css';
import { useNavigate } from 'react-router-dom';
import { getProducts, saveProduct, updateProduct, deleteProduct } from './services/apiService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function MessageComponent() {
    const [products, setProducts] = useState([]);
    const [newProductData, setNewProductData] = useState({ name: '', description: '' });
    const [showProducts, setShowProducts] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [showMessages, setShowMessages] = useState(false);
    const [activeAction, setActiveAction] = useState(null);
    const [newId, setNewId] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [newContent, setNewContent] = useState('');
    const [newImage, setNewImage] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [selectedPhotoId, setSelectedPhotoId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMessages();
        fetchProducts();
        fetchPhotos();
    }, []);


    const handleUploadProductPhoto = async () => {
        if (!selectedProductId || !newImage) {
            alert('Por favor, selecione um produto e uma foto.');
            return;
        }

        try {
            const response = await saveProductPhoto(newImage, selectedProductId);
            console.log('Foto do produto carregada com sucesso:', response);
            alert('Foto carregada com sucesso!');
            setNewImage(null);
            setActiveAction(null);
        } catch (error) {
            console.error('Erro ao carregar a foto do produto:', error);
            alert('Erro ao carregar a foto do produto');
        }
    };

    const fetchProducts = async () => {
        try {
            const data = await getProducts();
            console.log(data);
            setProducts(data);
        } catch (error) {
            console.error('Failed to fetch products:', error.response || error.message || error);
        }
    };

    const handleSaveProduct = async () => {
        if (!newProductData.name || newProductData.name.length < 5) {
            alert('O nome do produto deve conter pelo menos 5 caracteres.');
            return;
        } else if (newProductData.name.length > 40) {
            alert('O nome do produto não pode exceder 40 caracteres.');
            return;
        }

        if (!newProductData.description || newProductData.description.length < 40) {
            alert('A descrição do produto deve conter pelo menos 40 caracteres.');
            return;
        } else if (newProductData.description.length > 100) {
            alert('A descrição do produto não pode exceder 100 caracteres.');
            return;
        }

        const productData = {
            name: newProductData.name,
            description: newProductData.description,
        };

        try {
            const response = await saveProduct(productData);
            console.log('Produto salvo:', response);
            fetchProducts();
            setNewProductData({ name: '', description: '' });
            alert('Produto criado com sucesso');
        } catch (error) {
            console.error('Erro ao criar produto:', error);
            alert('Erro ao criar produto');
        }
    };


    const handleUpdateProduct = async () => {
        if (!newProductData.name || newProductData.name.length < 5) {
            alert('O nome do produto deve conter pelo menos 5 caracteres.');
            return;
        } else if (newProductData.name.length > 40) {
            alert('O nome do produto não pode exceder 40 caracteres.');
            return;
        }

        if (!newProductData.description || newProductData.description.length < 40) {
            alert('A descrição do produto deve conter pelo menos 40 caracteres.');
            return;
        } else if (newProductData.description.length > 100) {
            alert('A descrição do produto não pode exceder 100 caracteres.');
            return;
        }

        if (!selectedProductId) {
            alert('Por favor, selecione um produto para atualizar.');
            return;
        }

        const updatedProduct = {
            id: selectedProductId,
            name: newProductData.name,
            description: newProductData.description,
        };

        try {
            await updateProduct(updatedProduct, selectedProductId);
            fetchProducts();
            setNewProductData({ name: '', description: '' });
            alert('Produto atualizado com sucesso!');
        } catch (error) {
            console.error('Erro ao atualizar produto', error);
            alert('Erro ao atualizar produto');
        }
    };


    const handleDeleteProduct = async () => {
        if (!selectedProductId) {
            alert('Por favor, selecione um produto para deletar.');
            return;
        }

        if (!window.confirm('Tem certeza que deseja deletar este produto?')) {
            return;
        }

        try {
            await deleteProduct(selectedProductId);
            fetchProducts();
            setSelectedProductId(null);
            alert('Produto apagado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar produto:', error);
            alert('Erro ao deletar produto');
        }
    };

    const fetchMessages = async () => {
        try {
            const data = await getMessages();
            setMessages(data);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const fetchPhotos = async () => {
        try {
            const data = await getPhotos();
            setPhotos(data);
        } catch (error) {
            console.error('Failed to fetch photos:', error);
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleSaveMessage = async () => {
        if (!newTitle || newTitle.length < 10) {
            alert('O título deve conter pelo menos 10 caracteres.');
            return;
        } else if (newTitle.length > 100) {
            alert('O título não pode exceder 100 caracteres.');
            return;
        }

        if (!newContent || newContent.length < 1000) {
            alert('O conteúdo deve conter pelo menos 1000 caracteres.');
            return;
        } else if (newContent.length > 4000) {
            alert('O conteúdo não pode exceder 4000 caracteres.');
            return;
        }

        const messageData = {
            title: newTitle,
            content: newContent,
        };

        try {
            const response = await saveMessage(messageData);
            fetchMessages();
            setNewTitle('');
            setNewContent('');
            setActiveAction(null);
            alert('Matéria criada com sucesso');
        } catch (error) {
            alert('Não foi possível salvar a mensagem');
        }
    };


    const handleUploadPhoto = async () => {
        if (!newId || !newImage) {
            alert('Por favor, selecione uma matéria e uma imagem.');
            return;
        }

        if (isNaN(newId) || !Number.isInteger(Number(newId))) {
            alert('ID da matéria inválido.');
            return;
        }

        try {
            const response = await savePhoto(newImage, newId);
            alert('Foto carregada com sucesso!');
            setNewImage(null);
            setActiveAction(null);
        } catch (error) {
            alert('Erro ao enviar a foto');
        }
    };

    const handleUpdateMessage = async () => {
        if (!newTitle || newTitle.length < 10) {
            alert('O título deve conter pelo menos 10 caracteres.');
            return;
        } else if (newTitle.length > 100) {
            alert('O título não pode exceder 100 caracteres.');
            return;
        }

        if (!newContent || newContent.length < 1000) {
            alert('O conteúdo deve conter pelo menos 1000 caracteres.');
            return;
        } else if (newContent.length > 4000) {
            alert('O conteúdo não pode exceder 4000 caracteres.');
            return;
        }

        if (!newId) {
            alert('Por favor, selecione a notícia e forneça o título e conteúdo.');
            return;
        }

        const updatedMessage = {
            id: newId,
            title: newTitle,
            content: newContent,
        };

        try {
            const response = await updateMessage(updatedMessage);
            fetchMessages();
            setNewTitle('');
            setNewContent('');
            setActiveAction(null);
            alert('Matéria atualizada com sucesso!');
        } catch (error) {
            console.error('Erro ao atualizar a matéria', error);
            alert('Erro ao atualizar a matéria');
        }
    };

    const handleDeleteMessage = async () => {
        if (!newId) {
            alert('Por favor, selecione uma matéria para deletar.');
            return;
        }
        if (!window.confirm('Tem certeza que quer apagar a matéria?')) {
            return;
        }

        try {
            await deleteMessage(newId);
            fetchMessages();
            setNewId('');
            setActiveAction(null);
            alert('Matéria apagada com sucesso!');
        } catch (error) {
            console.error('Failed to delete message:', error);
            alert('Failed to delete message. Please try again.');
        }
    };

    const handleDeletePhoto = async () => {
        if (!selectedPhotoId) {
            alert('Por favor, selecione uma foto para deletar.');
            return;
        }

        if (!window.confirm('Tem certeza que quer apagar a foto?')) {
            return;
        }

        try {
            await deletePhoto(selectedPhotoId);
            alert('Foto apagada com sucesso!');
            fetchPhotos();
            setSelectedPhotoId(null);
        } catch (error) {
            console.error('Failed to delete photo:', error);
            alert('Erro ao deletar a foto');
        }
    };

    const handleUpdatePhoto = async () => {
        if (!selectedPhotoId || !newImage) {
            alert('Por favor, selecione uma foto e forneça uma nova imagem.');
            return;
        }

        try {
            await updatePhoto({ id: selectedPhotoId, fileData: newImage });
            alert('Foto atualizada com sucesso!');
            setNewImage(null);
            setActiveAction(null);
            fetchPhotos();
        } catch (error) {
            console.error('Failed to update photo:', error);
            alert('Erro ao atualizar a foto');
        }
    };

    const renderActionForm = () => {
        switch (activeAction) {
            case 'save':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Criar matéria</h2>
                        <small>O título deve conter entre 10 e 100 caracteres</small>
                        <br></br>
                        <small>O conteúdo deve conter entre 1000 e 4000 caracteres</small>
                        <input
                            type="text"
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            placeholder="Título"
                            required
                        />
                        <ReactQuill
                            value={newContent}
                            onChange={setNewContent}
                            placeholder="Conteúdo"
                            theme="snow"
                            required
                        />
                        <button onClick={handleSaveMessage}>Salvar notícia</button>
                    </form>
                );
            case 'productSave':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Criar produto</h2>
                        <small>O nome do produto deve conter entre 5 e 40 caracteres</small>
                        <br></br>
                        <small>A descrição do produto deve conter entre 40 e 100 caracteres</small>
                        <input
                            type="text"
                            value={newProductData.name}
                            onChange={(e) => setNewProductData({ ...newProductData, name: e.target.value })}
                            placeholder="Nome do produto"
                            required
                        />
                        <ReactQuill
                            value={newProductData.description}
                            onChange={(value) => setNewProductData({ ...newProductData, description: value })}
                            placeholder="Descrição do produto"
                            theme="snow"
                            required
                        />
                        <button onClick={handleSaveProduct}>Salvar produto</button>
                    </form>
                );
            case 'productUpdate':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Atualizar produto</h2>
                        <small>O nome do produto deve conter entre 5 e 40 caracteres</small>
                        <br></br>
                        <small>A descrição do produto deve conter entre 40 e 100 caracteres</small>
                        <br></br>
                        <select value={selectedProductId} onChange={(e) => setSelectedProductId(e.target.value)} required>
                            <option value="">Selecione um produto</option>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            value={newProductData.name}
                            onChange={(e) => setNewProductData({ ...newProductData, name: e.target.value })}
                            placeholder="Nome do produto"
                            required
                        />
                        <ReactQuill
                            value={newProductData.description}
                            onChange={(value) => setNewProductData({ ...newProductData, description: value })}
                            placeholder="Descrição do produto"
                            theme="snow"
                            required
                        />
                        <button onClick={handleUpdateProduct}>Atualizar produto</button>
                    </form>
                );
            case 'productDelete':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Apagar produto</h2>
                        <p>OBS: Caso o produto possua fotos, é necessário apagar as fotos relacionadas ao produto antes.</p>
                        <select value={selectedProductId} onChange={(e) => setSelectedProductId(e.target.value)} required>
                            <option value="">Selecione um produto</option>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))}
                        </select>
                        <button onClick={handleDeleteProduct}>Deletar produto</button>
                    </form>
                );

            case 'upload':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Upload de foto</h2>
                        <p>A imagem deve ter no máximo 10MB (máximo de 2 imagens por post).</p>
                        <small>Renomeie a foto para facilitar a identificação caso necessite apagá-la posteriormente.</small>
                        <br></br>
                        <small>Ex: Caso suba uma imagem para a matéria "Como foi a minha presença no evento?" renomeie a foto para "matéria-presençaEvento-imagem1.png"</small>
                        <br></br>
                        <select value={newId} onChange={(e) => setNewId(e.target.value)} required>
                            <option value="">Selecione uma matéria</option>
                            {messages.map((message) => (
                                <option key={message.id} value={message.id}>
                                    {message.title}
                                </option>
                            ))}
                        </select>
                        <input
                            type="file"
                            onChange={(e) => setNewImage(e.target.files[0])}
                            accept="image/*"
                            required
                        />
                        <button onClick={handleUploadPhoto}>Upload foto</button>
                    </form>
                );
            case 'update':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Atualizar matéria</h2>
                        <small>O título deve conter entre 10 e 100 caracteres</small>
                        <br></br>
                        <small>O conteúdo deve conter entre 1000 e 4000 caracteres</small>
                        <br></br>
                        <select value={newId} onChange={(e) => setNewId(e.target.value)} required>
                            <option value="">Selecione uma matéria</option>
                            {messages.map((message) => (
                                <option key={message.id} value={message.id}>
                                    {message.title}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            placeholder="Title"
                            required
                        />
                        <ReactQuill
                            value={newContent}
                            onChange={setNewContent}
                            placeholder="Content"
                            theme="snow"
                            required
                        />
                        <button onClick={handleUpdateMessage}>Atualizar matéria</button>
                    </form>
                );
            case 'updatePhoto':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Atualizar foto</h2>
                        <small>Caso a foto não estiver aparecendo, atualize a página.</small>
                        <br></br>
                        <select
                            value={selectedPhotoId}
                            onChange={(e) => setSelectedPhotoId(e.target.value)}
                            required
                        >
                            <option value="">Selecione uma foto</option>
                            {photos.map((photo) => (
                                <option key={photo.id} value={photo.id}>
                                    {photo.fileName}
                                </option>
                            ))}
                        </select>
                        <input
                            type="file"
                            onChange={(e) => setNewImage(e.target.files[0])}
                            accept="image/*"
                            required
                        />
                        <button onClick={handleUpdatePhoto}>Atualizar foto</button>
                    </form>
                );
            case 'delete':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Apagar matéria</h2>
                        <p>OBS: Caso a matéria possua fotos, é necessário apagar as fotos relacionadas a matéria antes.</p>
                        <select value={newId} onChange={(e) => setNewId(e.target.value)} required>
                            <option value="">Selecione uma matéria</option>
                            {messages.map((message) => (
                                <option key={message.id} value={message.id}>
                                    {message.title}
                                </option>
                            ))}
                        </select>
                        <button onClick={handleDeleteMessage}>Deletar notícia</button>
                    </form>
                );
            case 'productPhotoUpload':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Upload de foto para produto</h2>
                        <p>A imagem deve ter no máximo 10MB (cada produto tem apenas uma imagem).</p>
                        <small>Renomeie a foto para facilitar a identificação caso necessite apagá-la posteriormente.</small>
                        <br></br>
                        <small>Ex: Caso suba uma imagem para o produto "Produto A - Matheus Boa Sorte?" renomeie a foto para "produto-produto1-imagem.png"</small>
                        <br></br>
                        <select value={selectedProductId} onChange={(e) => setSelectedProductId(e.target.value)} required>
                            <option value="">Selecione um produto</option>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))}
                        </select>
                        <br />
                        <input
                            type="file"
                            onChange={(e) => setNewImage(e.target.files[0])}
                            accept="image/*"
                            required
                        />
                        <button onClick={handleUploadProductPhoto}>Upload foto</button>
                    </form>
                );
            case 'deletePhoto':
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="action-form">
                        <h2>Apagar foto</h2>
                        <small>Caso a foto não estiver aparecendo, atualize a página.</small>
                        <br></br>
                        <select
                            value={selectedPhotoId}
                            onChange={(e) => setSelectedPhotoId(e.target.value)}
                            required
                        >
                            <option value="">Selecione uma foto</option>
                            {photos.map((photo) => (
                                <option key={photo.id} value={photo.id}>
                                    {photo.fileName}
                                </option>
                            ))}
                        </select>
                        <br></br>
                        <button onClick={handleDeletePhoto}>Deletar foto</button>
                    </form>
                );
            default:
                return null;
        }
    };

    return (
        <div className="admin-container">
            <div className="sidebar">
                <h1>Admin</h1>
                <div className="admin-actions">
                    <h1>Matérias</h1>
                    <button onClick={() => { setShowMessages(!showMessages); setActiveAction(null); }}>
                        {showMessages ? 'Esconder matérias' : 'Listar matérias'}
                    </button>
                    <button onClick={() => setActiveAction('save')}>Criar matéria</button>
                    <button onClick={() => setActiveAction('update')}>Atualizar matéria</button>
                    <button onClick={() => setActiveAction('delete')}>Apagar matéria</button>
                    <h1>Arquivos</h1>
                    <button onClick={() => setActiveAction('upload')}>Upload de foto (matéria)</button>
                    <button onClick={() => setActiveAction('updatePhoto')}>Atualizar foto</button>
                    <button onClick={() => setActiveAction('deletePhoto')}>Apagar foto</button>
                    <button onClick={() => setActiveAction('productPhotoUpload')}>Upload de foto (produto)</button>
                    <h1>Produtos</h1>
                    <button onClick={() => setShowProducts(!showProducts)}>Listar produtos</button>
                    <button onClick={() => setActiveAction('productSave')}>Criar produto</button>
                    <button onClick={() => setActiveAction('productUpdate')}>Atualizar produto</button>
                    <button onClick={() => setActiveAction('productDelete')}>Deletar produto</button>


                </div>
                <button onClick={handleLogout} className="logout-btn">Logout</button>
            </div>
            <div className="content">
                {showMessages && (
                    <div className="news-list">
                        <ul>
                            {messages.map((message) => (
                                <li key={message.id}>
                                    <strong>ID: {message.id}</strong>
                                    <p><strong>Titulo:</strong> {message.title}</p>
                                    <p><strong>Conteúdo:</strong> {message.content}</p>
                                    {message.file && (
                                        <img
                                            src={`http://localhost:8080/uploads/${message.file.fileName}`}
                                            alt={message.file.fileName}
                                        />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {showProducts && (
                    <div className="product-list">
                        <ul>
                            {products.map((product) => (
                                <li key={product.id}>
                                    <strong>{product.name}</strong>
                                    <p>{product.description}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {renderActionForm()}
            </div>
        </div>
    );
}

export default MessageComponent;