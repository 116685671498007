import React from 'react'
import Footer from '../Footer'
import Hero from '../Hero'
import BlogComponent from '../BlogComponent'
import Nav from '../Nav'

function Blog() {
    return (
        <>
            <Nav></Nav>
            <Hero></Hero>
            <BlogComponent></BlogComponent>
            <Footer></Footer>
        </>
    )
}

export default Blog