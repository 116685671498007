import React, { useState, useEffect } from 'react';
import photoImg from '../resources/imgCondeuba.jpeg';
import photoImg2 from '../resources/imgSaoFrancisco.jpg';
import photoImg3 from '../resources/imgJaca.jpeg';
import photoImg4 from '../resources/imgSertaneja.jpeg';
import photoImg5 from '../resources/imgSertaneja.jpeg';
import photoImg6 from '../resources/imgSertao.jpeg';
import photoImg7 from '../resources/imgDubai.jpeg';
import photoImg8 from '../resources/imgCondeuba.jpeg';
import './PhotoSec.css';

function PhotoSec() {
    const photos = [
        { img: photoImg},
        { img: photoImg2},
        { img: photoImg3},
        { img: photoImg4},
        { img: photoImg5},
        { img: photoImg6},
        { img: photoImg7},
        { img: photoImg8}
    ];
    
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % photos.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [photos.length]);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    const goToPrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + photos.length) % photos.length);
    };

    const goToNext = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % photos.length);
    };

    return (
        <div className="photo-section">
            <div className="photo-container">


                <div className="photo-slide">
                    <div className="photo-item-wrapper">
                        <img src={photos[currentIndex].img} alt="Slideshow" className="photo-item" />
                    </div>
                </div>


                <div className="controls">
                    {photos.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${currentIndex === index ? 'active' : ''}`}
                            onClick={() => goToSlide(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PhotoSec;
