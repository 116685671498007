import React, { useEffect, useState } from 'react';
import { getPhotos } from './services/apiService';
import './ProductHighlights.css';

const ProductHighlights = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const data = await getPhotos();
        const productImages = data.filter(image => image.product);

        console.log("Filtered Product Images: ", productImages);

        setProducts(productImages);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="product-highlights-container">
      <div className="product-grid">
        {products.length > 0 ? (
          products.slice(0, 3).map((product, index) => {
            if (!product.product || !product.product.id) {
              console.error("Product or product.id is missing:", product);
              return null;
            }

            return (
              <div
                key={product.id}
                className={`product-card ${index === 0 ? 'large' : 'small'}`}
              >
                <a href={`/products/product-${product.product.id}`} className="product-card-link">
                  <img
                    src={`data:${product.contentType};base64,${product.fileData}`}
                    alt={`Product ${product.product.id}`}
                    className="product-card-image"
                  />
                  <span className="product-card-overlay">Ver mais...</span>
                </a>
              </div>
            );
          })
        ) : (
          <p>Carregando nossos produtos...</p>
        )}
      </div>
    </div>
  );
};

export default ProductHighlights;
