import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const isTokenValid = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        console.log('No token found.');
        return false;
    }

    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Date.now() / 1000;

    if (tokenPayload.exp <= currentTime) {
        console.log('Token expired.');
        return false;
    }

    return true;
};

const PrivateRoute = ({ element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        const checkToken = () => {
            const isValid = isTokenValid();
            setIsAuthenticated(isValid);
        };

        checkToken();

        const intervalId = setInterval(checkToken, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    console.log('Is authenticated:', isAuthenticated);

    return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;