import React from 'react';
import '../App.css';
import { Button } from './Button';
import './Hero.css';
import { useNavigate } from 'react-router-dom';

function HeroSection() {
  const handleScroll = () => {
    const scrollHeight = -window.innerHeight

    routeChange();

    window.scrollBy({
      top: scrollHeight,
      behavior: 'smooth'
    });
  };

  let navigate = useNavigate()
  const routeChange = () => {
    let path = '/sobre';
    navigate(path);
  }

  return (
    <div className='hero-section'>
      <div className='hero-container'>
        <div className='hero-btns'>

        </div>
      </div>
    </div>
  );
}

export default HeroSection;