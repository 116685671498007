import React, { useEffect, useState } from 'react';
import './Books.css';
import { getPhotos } from './services/apiService';

function Books() {
    const [isVisible, setIsVisible] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100);

        const fetchProducts = async () => {
            try {
                const data = await getPhotos();
                const productImages = data.filter(image => image.product);
                setProducts(productImages);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`books-container ${isVisible ? 'visible' : ''}`}>
            <h1 className='books-title'>NOSSOS PRODUTOS</h1>
            <div className="books-row">
                {products.length > 0 ? (
                    products.map((product, index) => {
                        if (!product.product || !product.product.id) {
                            console.error("Product or product.id is missing:", product);
                            return null;
                        }

                        return (
                            <div key={product.id} className="books-column">
                                <a href={`/products/product-${product.product.id}`} className="book-link">
                                    <img
                                        src={`data:${product.contentType};base64,${product.fileData}`}
                                        alt={`Product ${product.product.id}`}
                                        className="book-photo"
                                    />
                                </a>
                            </div>
                        );
                    })
                ) : (
                    <p>Carregando nossos produtos..</p>
                )}
            </div>
        </div>
    );
}

export default Books;
