import React from 'react'
import Books from '../Books'
import Footer from '../Footer'
import Nav from '../Nav'

function Produtos() {
    return (
        <>
            <Nav></Nav>
            <Books></Books>
            <Footer></Footer>
        </>
    )
}

export default Produtos