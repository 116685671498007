import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import { useEffect, useState } from 'react';

import imgAratu from '../resources/imgAratuOn.png';
import imgJornalDaChapada from '../resources/imgJornalDaChapada.jpg';


function Noticias() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`cards ${isVisible ? 'visible' : ''}`}>
      <h1 className='cards-mainh1'></h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={imgAratu}
              href='https://aratuon.com.br/variedades/gratidao-em-video-matheus-boa-sorte-agradece-premio-dado-ao-dende-na-mochila'
              text='Vencedor do premio de "melhor programa local de 2016" com o programa Dende na Mochila.'
              label='@AratuOn'
              path='/'
            />
            <CardItem
              src={imgJornalDaChapada}
              href='https://jornaldachapada.com.br/2024/10/22/video-em-reportagem-para-seu-canal-no-youtube-jornalista-matheus-boa-sorte-exalta-atrativos-turisticos-cultura-e-culinaria-de-piata/'
              text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet iaculis mauris dapibus consequat. Ut volutpat pharetra lectus, eu fringilla sapien consequat in.'
              label='@JornalDaChapada'
              path='/'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Noticias

