import React, { useState, useEffect } from 'react';
import './TestimonialCarousel.css';

const testimonials = [
  {
    id: 1,
    photo: "https://via.placeholder.com/100",
    text: "This is the best experience I've ever had! Highly recommend it to everyone!",
    author: "John Doe",
  },
  {
    id: 2,
    photo: "https://via.placeholder.com/100",
    text: "I can't wait for the next event! Truly a game-changer!",
    author: "Jane Smith",
  },
  {
    id: 3,
    photo: "https://via.placeholder.com/100",
    text: "Incredible service, and the atmosphere was out of this world!",
    author: "Carlos Lopez",
  },
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-background">
      <div className="testimonial-container">
        <div className="testimonial-carousel">
          {testimonials.map((testimonial, index) => (
            <div
              key={testimonial.id}
              className="testimonial-slide"
              style={{
                transform: `translateX(-${100 * currentIndex}%)`,
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              <img
                src={testimonial.photo}
                alt={`Photo of ${testimonial.author}`}
                className="testimonial-photo"
              />
              <p className="testimonial-text">"{testimonial.text}"</p>
              <p className="testimonial-author">{testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;