import React, { useState } from 'react';
import './VideoSec.css';

function VideoSec() {
    const [isPlaying, setIsPlaying] = useState(false);
    const handlePlay = () => {
        setIsPlaying(true);
    };

    return (
        <div className='video-section'>
            <div className='video-container'>
                {!isPlaying && (
                    <div className='video-overlay' onClick={handlePlay}>
                        <img
                            src="https://img.youtube.com/vi/3OD5hOGmxcE/maxresdefault.jpg"
                            alt="Video Thumbnail"
                            className="video-thumbnail"
                        />
                        <div className='custom-play-button'>▶</div>
                    </div>
                )}
                {isPlaying && (
                    <iframe
                        className='video-frame'
                        src="https://www.youtube.com/embed/3OD5hOGmxcE?si=HvZF7EfQ5zMTNwLe&autoplay=1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        frameBorder="0"
                    ></iframe>
                )}
            </div>
        </div>
    );
}

export default VideoSec;