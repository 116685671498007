import React, { useState } from 'react';
import './FaqSec.css';

function FaqSec() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-section">
            <h2 className='faq-title'>Perguntas frequentes (FAQ)</h2>
            <div className="accordion">
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 0}
                        onClick={() => toggleAccordion(0)}
                    >
                        <span className="accordion-title">Primeira pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 0 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 1}
                        onClick={() => toggleAccordion(1)}
                    >
                        <span className="accordion-title">Segunda pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 1 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 2}
                        onClick={() => toggleAccordion(2)}
                    >
                        <span className="accordion-title">Terceira pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 2 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 3}
                        onClick={() => toggleAccordion(3)}
                    >
                        <span className="accordion-title">Quarta pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 3 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 4}
                        onClick={() => toggleAccordion(4)}
                    >
                        <span className="accordion-title">Quinta pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 4 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 5}
                        onClick={() => toggleAccordion(5)}
                    >
                        <span className="accordion-title">Sexta pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 5 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 6}
                        onClick={() => toggleAccordion(6)}
                    >
                        <span className="accordion-title">Setima pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 6 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 7}
                        onClick={() => toggleAccordion(7)}
                    >
                        <span className="accordion-title">Oitava pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 7 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 8}
                        onClick={() => toggleAccordion(8)}
                    >
                        <span className="accordion-title">Nona pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 8 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
                <div className="accordion-item">
                    <button
                        id="accordion-button-1"
                        aria-expanded={activeIndex === 9}
                        onClick={() => toggleAccordion(9)}
                    >
                        <span className="accordion-title">Decima pergunta de teste</span>
                        <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeIndex === 9 && (
                        <div className="accordion-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue enim, non aliquam arcu viverra ut.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FaqSec;