import React from 'react';
import './AboutSpec.css';

function AboutSpec() {
    return (
        <div className='aboutspec-container'>
            <div className='aboutspec-content'>
                <h1 className='aboutspec-title'>Sobre o evento</h1>
                <p className='aboutspec-text'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ac massa eu neque cursus tempor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis venenatis bibendum risus, vitae bibendum orci vulputate quis. Morbi porta elementum ante, sit amet bibendum velit consectetur ut. Vivamus tincidunt ac nunc ac imperdiet. Nam a elit vehicula, faucibus ante et, pretium mi. Donec ornare nisi nec tellus semper, in elementum lorem aliquet. Mauris placerat efficitur nibh. Aliquam tempor arcu vel feugiat bibendum.
                </p>
                <p className='aboutspec-text'>
                    Nunc arcu urna, dapibus non nisl et, sollicitudin vulputate nulla. Proin quis sem elementum, rutrum nisi sodales, ultrices justo. Nam neque metus, consectetur et fringilla sit amet, posuere quis elit. Sed dictum, leo vel venenatis euismod, eros neque iaculis eros, a efficitur lectus tortor in quam. Sed et ornare dui, id mollis ex. Ut egestas justo quis eros tristique, eget tristique neque placerat. Curabitur condimentum faucibus quam, et ultrices eros tristique at.
                </p>
                <p className='aboutspec-text'>
                    Proin pharetra eu purus vel egestas. Nullam nec elementum massa. Proin sodales neque maximus, tincidunt odio at, tempor lacus. Maecenas fermentum at nisi eget mollis. Nulla semper id tortor at porta. Phasellus vitae convallis augue. Aenean auctor accumsan massa quis tristique. In sed lacinia nisi. Aenean molestie enim in purus tincidunt convallis. Nullam dictum mauris ut consectetur commodo. Mauris bibendum semper justo ut tempus.
                </p>
            </div>
        </div>
    );
}

export default AboutSpec;