import React, { useRef } from 'react';
import './Marcas.css';
import imgPlaceHolder1 from '../resources/imgAratuOn.png';

function Marcas() {
    const scrollRef = useRef(null);

    const scroll = (direction) => {
        if (scrollRef.current) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    return (
        <div className='brands-section'>
            <h1 className='carousel-title'>Marcas que confiaram no meu trabalho</h1>

            <div className='brands-container' ref={scrollRef}>
                <img src={imgPlaceHolder1} alt="Brand 1" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 2" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 3" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 4" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 5" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 6" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 7" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 8" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 9" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 10" className="brand-image" />
                <img src={imgPlaceHolder1} alt="Brand 11" className="brand-image" />
            </div>

            <button className="arrow left-arrow" onClick={() => scroll('left')}>‹</button>
            <button className="arrow right-arrow" onClick={() => scroll('right')}>›</button>
        </div>
    );
}

export default Marcas;
