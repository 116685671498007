import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  const { href, path } = props;

  return (
    <li className='cards__item'>
      {href ? (
        <a className='cards__item__link' href={href} target="_blank" rel="noopener noreferrer">
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </a>
      ) : (
        <Link className='cards__item__link' to={path}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </Link>
      )}
    </li>
  );
}

export default CardItem;