import '../../App.css';
import ArticleHighlights from '../ArticleHighlights';
import BlogHighlights from '../BlogHighlights';
import Hero from '../Hero';
import Nav from '../Nav';
import ProductHighlights from '../ProductHighlights';
import TestimonialCarousel from '../TestimonialCarousel';
import Footer from '../Footer';

function Home() {
  return (
    <>
      <Nav></Nav>
      <Hero></Hero>
      <ProductHighlights></ProductHighlights>
      <TestimonialCarousel></TestimonialCarousel>
      <ArticleHighlights></ArticleHighlights>
      <BlogHighlights></BlogHighlights>
      <Footer></Footer>
    </>
  )
}

export default Home;