import React from 'react'
import Footer from '../Footer'
import Noticias from '../Noticias'
import Nav from '../Nav'

function Imprensa() {
    return (
        <>
        <Nav></Nav>
        <Noticias></Noticias>
            <Footer></Footer>
        </>
    )
}

export default Imprensa